.footer {
    flex-shrink: 0;
    text-align: center;
    background-color: rgb(236, 236, 236);
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 30px;
  }

.font {
    color: black;
}

