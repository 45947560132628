.video {
  display: flex;
  justify-content: center;
}

.videoBox{
width: 864px;
height: 491px;
display: flex;
justify-content: center;
margin-top: 20px;
border: 2px solid #F8F9FA;
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.19));
  box-shadow: 0px 6px 20px rgba(56, 125, 255, 0.3)

}

.input_field{
  width: 20px;
  height: 10px;
}

.camera {
display: flex;
justify-content: center;
}

.chechbox {
  margin-left: 10px;
}

.data {
  margin-top: 10px;
}
.font_position{
  margin-left: 100px;
  padding: 3%;
}

.email{
 padding: 10%;
}

.minPos{
  margin-left: auto;
  margin-right: auto;
  width: 25em;
  text-align: center;
  padding-bottom: 30px;
}

.img{
  margin-top: -10px;
  height: 180px;
  width: 300px; 
}

.inline{
  display: inline-block;
  padding-bottom: 15px;
}

.div1 {
  width: 30px;
  height: 30px;
  background-color: #B7BAFC;
  border-color: #58FCEC;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: -2px;
}

.div2 {
  width: 30px;
  height: 30px;
  background-color: #F1ADAC;
  border-color: #B7BAFC;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: -2px;
}

.cameraBox {
  width: 190px;
  height: 130px;
  margin-top: 85px;
  border:10px solid red;
  text-align: center;
}

/*.background{
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: auto;
  object-fit: contain;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 120%;
}*/

.shadow{
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.19));
  box-shadow: 0px 6px 20px rgba(56, 125, 255, 0.3)
}

.background {
  background: url('./Images/Background.jpg') center center/cover no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  z-index: -1;
  position: absolute;
}


@media only screen and (max-height: 826px) {
  .background {
    height: 140vh;
    width: 100%;
  }
}

@media only screen and (max-height: 600px) {
  .background {
    height: 230vh;
    width: 100%;
  }
}

@media only screen and (max-height: 340px) {
  .background {
    height: 275vh;
    width: 100%;
  }
}

/* Extra small screen / smartphone / 280px to 479px */
@media (max-width: 851px) {
  .background{
    width: 900px;
    height: 160vh;
  }
}

@media (max-width: 600px) {
  .background{
    width: 900px;
    height: 190vh;
  }
}

@media (max-width: 485px) {
  .background{
    width: 650px;
    height: 190vh;
  }
}

@media (max-width: 350px) {
  .background{
    width: 600px;
    height: 350vw;
  }
}

@media (max-width: 320px) {
  .background{
    width: 580px;
    height: 520vw;
  }
}
