.personalities{
    margin-top: 100px;
    text-align: center;
    margin-left: 10px;
}

.background {
    background: url('./Images/Background.jpg') center center/cover no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    z-index: -1;
    position: absolute;
  }